import revive_payload_client_4sVQNw7RlN from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/msservice/source/prod/fe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/msservice/source/prod/fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/msservice/source/prod/fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/home/msservice/source/prod/fe/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_iGLjBbor9q from "/home/msservice/source/prod/fe/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_t2GMTTFnMT from "/home/msservice/source/prod/fe/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/home/msservice/source/prod/fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/msservice/source/prod/fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/home/msservice/source/prod/fe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import LoginByToken_client_zymGD2y5e4 from "/home/msservice/source/prod/fe/plugins/LoginByToken.client.js";
import i18n_sVHQBgnb3t from "/home/msservice/source/prod/fe/plugins/i18n.js";
import livechat_client_sncPEtBvRJ from "/home/msservice/source/prod/fe/plugins/livechat.client.js";
import socket_client_N9cHubToyQ from "/home/msservice/source/prod/fe/plugins/socket.client.js";
import starter_client_Cz4Nib8WYG from "/home/msservice/source/prod/fe/plugins/starter.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_iGLjBbor9q,
  plugin_t2GMTTFnMT,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  LoginByToken_client_zymGD2y5e4,
  i18n_sVHQBgnb3t,
  livechat_client_sncPEtBvRJ,
  socket_client_N9cHubToyQ,
  starter_client_Cz4Nib8WYG
]